import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private auth: AuthService,
    private userService: UserService,
    private router: Router,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canActivate(): Observable<boolean> {
    return this.auth.authStatus$.pipe(
      filter((s) => s.loggedIn !== undefined),
      switchMap(async (status) => {
        return status?.loggedIn ?? false;
      }),
      first(),
      catchError(() => of(false)),
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.userService.roles$.pipe(map((roles) => roles.includes('admin') || roles.includes('booking')));
        }
        return of(isLoggedIn);
      }),
      tap((res) => {
        if (!res) {
          this.router.navigate(['/login'], { replaceUrl: true });
        }
      }),
    );
  }
}
