import { Injectable, inject } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@core/core.constants';
import { Edition, Stage } from '@core/models/edition.model';
import { ReservationItem } from '@core/models/reservation-item.model';
import { isSameDay } from 'date-fns';
import { BehaviorSubject, combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { AdminFirestoreService } from '../../services/admin-firestore.service';

@Injectable({
  providedIn: 'root',
})
export class EditionService {
  firestore = inject(AdminFirestoreService);

  private editionId = new BehaviorSubject('');
  public editionId$ = this.editionId.asObservable();
  public edition$ = this.editionId$.pipe(
    switchMap((editionId: string) => this.getEdition(editionId)),
    shareReplay(1),
  );

  public editions$ = this.firestore.listDocumentsWithId<Edition>(COLLECTIONS.EDITIONS);
  public activeEditions$ = this.firestore.listDocumentsWithId<Edition>(
    COLLECTIONS.EDITIONS,
    where('isActive', '==', true),
  );

  public reservationItems$ = this.editionId$.pipe(
    switchMap((editionId: string) =>
      this.firestore.listDocumentsWithId<ReservationItem>(
        COLLECTIONS.RESERVATION_ITEMS,
        where('editionId', '==', editionId),
      ),
    ),
    shareReplay(1),
  );

  hotels$ = this.firestore
    .listDocumentsWithId<ReservationItem>(COLLECTIONS.RESERVATION_ITEMS, where('itemType', '==', 'HOTEL'))
    .pipe(
      map((reservationItems: ReservationItem[]) => {
        const uniqueHotels = reservationItems.reduce((accumulator, item) => {
          if (
            !accumulator.find((hotel) => hotel.name.trim().toLocaleLowerCase() === item.name.trim().toLocaleLowerCase())
          ) {
            accumulator.push(item);
          }
          return accumulator;
        }, [] as ReservationItem[]);

        return uniqueHotels;
      }),
    );

  ferrys$ = this.firestore
    .listDocumentsWithId<ReservationItem>(COLLECTIONS.RESERVATION_ITEMS, where('itemType', '==', 'FERRY'))
    .pipe(
      map((reservationItems: ReservationItem[]) => {
        const uniqueHotels = reservationItems.reduce((accumulator, item) => {
          if (
            !accumulator.find((ferry) => ferry.name.trim().toLocaleLowerCase() === item.name.trim().toLocaleLowerCase())
          ) {
            accumulator.push(item);
          }
          return accumulator;
        }, [] as ReservationItem[]);

        return uniqueHotels;
      }),
    );

  getReservationItemsByEdition(editionId: string) {
    return this.firestore.listDocumentsWithId<ReservationItem>(
      COLLECTIONS.RESERVATION_ITEMS,
      where('editionId', '==', editionId),
    );
  }

  getStagesReservationItems(editionId: string) {
    return combineLatest([this.getEditionStages(editionId), this.getReservationItemsByEdition(editionId)]).pipe(
      map(([allStages, allReservationItems]) => {
        const filteredStages = allStages.filter((stage) => stage.hasFerry || stage.hasHotel);
        return filteredStages.map((stage) => {
          const reservationItems = allReservationItems.filter((b) => isSameDay(b.date, stage.dateTime));
          const hotels = reservationItems.filter((a) => a.itemType === 'HOTEL');
          const ferrys = reservationItems.filter((a) => a.itemType === 'FERRY');
          return {
            ...stage,
            hotels,
            ferrys,
          };
        });
      }),
    );
  }

  async createEdition(data: Omit<Edition, 'id'>, stages: Partial<Stage>[]) {
    await this.firestore.create(COLLECTIONS.EDITIONS, this.prepareDataToSaveEdition(data)).then((editionId: string) => {
      stages.map(async (stage, index) => {
        await this.firestore.createOrUpdate(
          [COLLECTIONS.EDITIONS, editionId, COLLECTIONS.EDITION_STAGES].join('/'),
          stage.id ?? '',
          {
            ...this.prepareDataToSaveStage(stage),
            stageNumber: index + 1,
          },
        );
      });
    });
  }

  async updateEdition(data: Omit<Edition, 'id'>, editionId: string, stages: Partial<Stage>[]) {
    return await this.firestore
      .update(COLLECTIONS.EDITIONS, {
        ...this.prepareDataToSaveEdition(data),
        id: editionId,
      })
      .then((_) => {
        stages.map(async (stage, index) => {
          await this.firestore.createOrUpdate(
            [COLLECTIONS.EDITIONS, editionId, COLLECTIONS.EDITION_STAGES].join('/'),
            stage.id ?? '',
            {
              ...this.prepareDataToSaveStage(stage),
              stageNumber: index + 1,
            },
          );
        });
      });
  }

  getEdition(editionId: string) {
    return this.firestore
      .getDocument<Edition>(COLLECTIONS.EDITIONS, editionId)
      .pipe(map((res) => this.prepareDates(res)));
  }

  getEditionStages(editionId: string) {
    return this.firestore
      .listDocumentsWithId<Stage>(
        [COLLECTIONS.EDITIONS, editionId, COLLECTIONS.EDITION_STAGES].join('/'),
        orderBy('stageNumber', 'asc'),
      )
      .pipe(map((res) => this.prepareStagesDates(res)));
  }

  createReservationItem(data: Partial<ReservationItem>) {
    return this.firestore.create(COLLECTIONS.RESERVATION_ITEMS, data);
  }

  updateReservationItem(data: Partial<ReservationItem>) {
    return this.firestore.update(COLLECTIONS.RESERVATION_ITEMS, data);
  }

  removeStage(editionId: string, stageId: string) {
    return this.firestore.deleteForever(
      [COLLECTIONS.EDITIONS, editionId, COLLECTIONS.EDITION_STAGES].join('/'),
      stageId,
    );
  }

  private prepareDataToSaveEdition(data: Omit<Edition, 'id'>) {
    const dataToSave = {
      ...data,
      rates: data.rates.map((rate) => {
        return {
          ...rate,
          startDate: rate.startDate.getTime(),
          endDate: rate.endDate.getTime(),
        };
      }),
    };
    return dataToSave;
  }

  private prepareDates(edition: Edition) {
    const convertedEdition = {
      ...edition,
      rates: edition.rates.map((rate) => {
        return {
          ...rate,
          startDate: new Date(rate.startDate),
          endDate: new Date(rate.endDate),
        };
      }),
    };
    return convertedEdition;
  }

  private prepareDataToSaveStage(stage: Partial<Stage>) {
    const dataToSave = {
      ...stage,
      dateTime: stage.dateTime?.getTime(),
    };
    return dataToSave;
  }

  prepareStagesDates(stages: Stage[]) {
    stages.map((stage) => {
      stage.dateTime = new Date(stage.dateTime);
    });
    return stages;
  }

  setEditionId(editionId: string) {
    this.editionId.next(editionId);
  }
}
