import { Component, OnInit, isDevMode } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'gt-admin-root',
  templateUrl: './app.component.html',
  styles: [
    `
      .dev {
        background-color: red;
        color: white;
        font-size: 12px;
        position: fixed;
        padding: 2px;
        right: -8vw;
        text-align: center;
        transform: rotate(45deg);
        width: 19vw;
        top: 1vw;
        pointer-events: none;
        z-index: 2;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  public isDev = isDevMode();
  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
