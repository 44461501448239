import { Inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  collectionGroup,
  CollectionReference,
  doc,
  docData,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  Query,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { ENVIRONMENT } from '@core/core.constants';
import { Entity } from '@core/models/common.model';
import { deleteDoc } from 'firebase/firestore';
import { forkJoin, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminFirestoreService {
  private documentsReaded = 0;

  constructor(
    private firetore: Firestore,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(ENVIRONMENT) private environment: any,
  ) {}

  getCollectionRef(path: string): CollectionReference<DocumentData> {
    return collection(this.firetore, path);
  }

  generateId(path: string): string {
    const col = this.getCollectionRef(path);
    return doc(col).id;
  }

  getDocumentRef<T = DocumentData>(path: string, id: string): DocumentReference<T> {
    const col = this.getCollectionRef(path);
    return doc(col, id) as DocumentReference<T>;
  }

  getDocument<T extends Entity>(collection: string, id: string): Observable<T> {
    const doc = this.getDocumentRef(collection, id) as DocumentReference<T>;
    return docData<T>(doc).pipe(
      tap(() => this.countDatabaseReads(1, [collection, id])),
      map((res: T | undefined) => {
        if (!res) {
          throw new Error(`Document "${id}" does not exist on "${collection}"`);
        }
        if (res.deleted) {
          throw new Error(`Document "${id}" from "${collection}" is deleted.`);
        }
        return res;
      }),
    );
  }

  getAllDocuments<T extends Entity>(collection: string, ids: string[]): Observable<T[]> {
    return forkJoin(ids.map((id) => this.getDocument<T>(collection, id)));
  }

  listDocuments<T extends Entity>(path: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    const collection = this.getCollectionRef(path);
    const q = query<T, DocumentData>(collection as unknown as Query<T>, ...queryConstraints);
    return collectionData<T>(q).pipe(tap((d) => this.countDatabaseReads(d.length, [path, ...queryConstraints])));
  }

  listDocumentsWithId<T extends Entity>(path: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    const collection = this.getCollectionRef(path);
    const q = query<T, DocumentData>(collection as unknown as Query<T>, ...queryConstraints);
    return collectionData<T>(q, {
      idField: 'id',
    }).pipe(
      tap((d) => this.countDatabaseReads(d.length, [path, ...queryConstraints])),
      map((s) => s.filter((d) => d.deleted !== true)),
    );
  }

  listDocumentsGroup<T extends Entity>(groupId: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    const q = query<T, DocumentData>(collectionGroup(this.firetore, groupId) as Query<T>, ...queryConstraints);
    return collectionData<T>(q, {
      idField: 'id',
    }).pipe(
      tap((d) => this.countDatabaseReads(d.length, [groupId, ...queryConstraints])),
      map((s) => s.filter((d) => d.deleted !== true)),
    );
  }

  exists(collection: string, id: string): Promise<boolean> {
    const doc = this.getDocumentRef(collection, id);
    this.countDatabaseReads(1, ['exists', id]);
    return getDoc(doc)
      .then((doc) => doc.exists())
      .catch(() => false);
  }

  async create<T extends Entity>(collection: string, data: Partial<T>): Promise<string> {
    const dbData = data;
    dbData.createdAt = Date.now();
    if (!dbData.id) {
      dbData.id = this.generateId(collection);
    }
    const col = this.getCollectionRef(collection);
    const document = doc(col, dbData.id);
    await setDoc<DocumentData, DocumentData>(document, dbData);
    return dbData.id;
  }

  async createOrUpdate<T extends Entity>(collection: string, id: string, data: Partial<T>): Promise<void> {
    if (data.id && data.id !== id) {
      throw new Error('Document Id mismatch from data id');
    }
    const dbData = data;
    const exists = await this.exists(collection, id);
    if (!exists) {
      dbData.createdAt = Date.now();
    }
    dbData.id = id;
    dbData.updatedAt = Date.now();
    const doc = this.getDocumentRef(collection, id);
    return setDoc<DocumentData, DocumentData>(doc, dbData, { merge: true });
  }

  async update<T extends Entity>(collection: string, data: Partial<T>): Promise<void> {
    const dbData = data;
    const id = data.id;
    if (!id) throw new Error('Document data does not contain ID');
    dbData.updatedAt = Date.now();
    const doc = this.getDocumentRef(collection, id);
    return updateDoc<DocumentData, DocumentData>(doc, dbData);
  }

  async delete(collection: string, id: string): Promise<void> {
    return this.update(collection, { id, deleted: true });
  }

  async deleteForever(collection: string, id: string): Promise<void> {
    const doc = this.getDocumentRef(collection, id);
    return deleteDoc(doc);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private countDatabaseReads(length: number, data: any[] = []) {
    if (this.environment.production) return;
    const newCount = this.documentsReaded + length;
    // eslint-disable-next-line no-restricted-syntax
    console.debug(
      '%c[FirestoreService]',
      'background: #F9CB36; color: #000',
      `Document reads: ${this.documentsReaded} (+${length}) => ${newCount}`,
      data,
    );
    this.documentsReaded = newCount;
  }
}
