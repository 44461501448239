import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '@core/models/common.model';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { MenuItem } from 'primeng/api';
import { map } from 'rxjs';
import { EditionService } from '../modules/editions/edition.service';

@Component({
  selector: 'gt-admin-layout',
  templateUrl: './layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: ElementRef;

  editionService = inject(EditionService);
  userService = inject(UserService);
  router = inject(Router);
  activeEditions$ = this.editionService.activeEditions$;
  currentEditionId = localStorage.getItem('editionId');

  navItems: MenuItem[] = [
    {
      label: 'Inicio',
      icon: 'pi pi-fw pi-home',
      routerLink: 'home',
      roles: ['admin'],
    },
    {
      label: 'Inscritos',
      icon: 'pi pi-fw pi-users',
      routerLink: 'inscriptions',
      roles: ['admin'],
    },
    {
      label: 'Ediciones',
      icon: 'pi pi-fw pi-flag',
      routerLink: 'editions',
      roles: ['admin'],
    },
    {
      label: 'Mensajes',
      icon: 'pi pi-fw pi-send',
      routerLink: 'messages',
      roles: ['admin'],
    },
    {
      label: 'Hoteles / Ferrys',
      icon: 'pi pi-fw pi-building',
      routerLink: 'reservation-items',
      roles: ['admin', 'booking'],
    },
    {
      label: 'Reservas',
      icon: 'pi pi-fw pi-book',
      routerLink: 'bookings',
      roles: ['admin', 'booking'],
    },
  ];

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    
  }

  checkRoles(itemRoles: Role[], userRoles: Role[]) {
    return itemRoles.some((role) => userRoles.includes(role));
  }

  ngOnInit(): void {
    this.userService.roles$
      .pipe(
        map((roles) => {
          this.navItems.forEach((item) => {
            item.visible = this.checkRoles(item['roles'], roles);
            this.cdr.detectChanges();
          });
        }),
      )
      .subscribe();
    if (this.currentEditionId) {
      this.editionService.setEditionId(this.currentEditionId);
    }
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEdition(event: any) {
    localStorage.setItem('editionId', event.value);
    this.editionService.setEditionId(event.value);
  }
}
