import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'inscriptions',
        loadChildren: () => import('./modules/inscriptions/inscriptions.module').then((m) => m.InscriptionsModule),
      },
      {
        path: 'editions',
        loadChildren: () => import('./modules/editions/editions.module').then((m) => m.EditionsModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/messages/messages.module').then((m) => m.MessagesModule),
      },
      {
        path: 'bookings',
        loadChildren: () => import('./modules/bookings/bookings.module').then((m) => m.BookingsModule),
      },
      {
        path: 'reservation-items',
        loadChildren: () =>
          import('./modules/reservation-items/reservation-items.module').then((m) => m.ReservationItemsModule),
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
