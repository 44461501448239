<div class="flex">
  <aside
    class="flex flex-col items-center w-28 h-screen py-8 bg-white border-r rtl:border-l rtl:border-r-0 dark:bg-gray-900 dark:border-gray-700"
  >
    <nav class="flex flex-col flex-1 gap-4">
      <button routerLink="home">
        <img class="w-full h-6" src="/assets/logos/logo.svg" alt="" />
      </button>
      <ng-container *ngFor="let item of navItems">
        <button
          [routerLink]="item.routerLink"
          *ngIf="item.visible"
          class="flex flex-col items-center gap-1 px-6 py-2 text-gray-700 focus:outline-nones transition-colors duration-200 rounded-lg dark:text-gray-200 dark:hover:bg-gray-800 hover:bg-gray-100"
        >
          <i class="text-2xl" [class]="item.icon"></i>
          <span class="text-xs">{{ item.label }}</span>
        </button>
      </ng-container>
    </nav>

    <div class="flex flex-col space-y-6 items-center px-2 cursor-pointer">
      <a
        (click)="logout()"
        class="flex flex-col items-center gap-1 px-6 py-2 text-gray-700 focus:outline-nones transition-colors duration-200 rounded-lg dark:text-gray-200 dark:hover:bg-gray-800 hover:bg-gray-100"
      >
        <i class="text-2xl pi pi-sign-out"></i>
        <span class="text-xs text-center">Logout</span>
      </a>
    </div>
  </aside>
  <div *ngIf="!router.url.includes('editions')" class="right-10 w-64 absolute" style="z-index: 1; top: 10px">
    <p-dropdown
      appendTo="body"
      (onChange)="setEdition($event)"
      [options]="(activeEditions$ | async) ?? []"
      optionLabel="name"
      optionValue="id"
      styleClass="w-full"
      [(ngModel)]="currentEditionId"
    >
    </p-dropdown>
  </div>
  <div class="flex flex-grow flex-col p-4">
    <router-outlet></router-outlet>
  </div>
</div>
